<template>
  <div>
    <div v-if="ready">
      <div class="d-flex justify-content-center">
        <h4>Dados Responsável Legal</h4>
      </div>
      <hr class="mb-4" />

      <label
        >Os dados do responsável legal é o mesmo do responsável financeiro?
      </label>
      <div class="form-check form-check-inline">
        <input
          v-model="same_data_from_financial"
          class="form-check-input"
          type="radio"
          id="same_data_resp1"
          :value="true"
        />
        <label class="form-check-label" for="same_data_resp1">SIM</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          v-model="same_data_from_financial"
          class="form-check-input"
          type="radio"
          id="same_data_resp2"
          :value="false"
        />
        <label class="form-check-label" for="same_data_resp2">Não</label>
      </div>

      <b-card
        class="mb-4"
        border-variant="secondary"
        header="Dados Pessoais"
        header-text-variant="secondary"
      >
        <b-row v-for="(schema, idx) in schema_info" :key="`legal-info-${idx}`">
          <form-input-simple
            v-for="(formField, idx) in schema"
            v-model="model[formField.model]"
            :key="idx"
            :formField="formField"            
            :errors="errors"
          />
        </b-row>
      </b-card>

      <b-card
        class="mb-4"
        border-variant="secondary"
        header="Contato"
        header-text-variant="secondary"
      >
        <b-row v-for="(schema, idx) in schema_contact" :key="`contato-${idx}`">
          <form-input-simple
            v-for="(formField, idx) in schema"
            v-model="model.contact[formField.model]"
            :key="idx"
            :formField="formField"           
            :errors="errorsContact"
          />
        </b-row>
      </b-card>

      <b-card
        class="mb-4"
        border-variant="secondary"
        header="Endereço Residencial"
        header-text-variant="secondary"
      >
        <b-row v-if="!same_data_from_financial">
          <b-col md="12">
            <label
              >O Endereço Residencial do Responsável Legal é o mesmo endereço do
              aluno?
            </label>
            <div class="form-check form-check-inline">
              <input
                v-model="home_address_igual_da_crianca"
                class="form-check-input"
                type="radio"
                id="legal_home_address_resp1"
                @change="homeAddressIgualDaCrianca(true)"
                :value="true"
              />
              <label class="form-check-label" for="legal_home_address_resp1"
                >SIM</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                v-model="home_address_igual_da_crianca"
                class="form-check-input"
                type="radio"
                id="legal_home_address_resp2"
                @change="homeAddressIgualDaCrianca(false)"
                :value="false"
              />
              <label class="form-check-label" for="legal_home_address_resp2"
                >Não</label
              >
            </div>
          </b-col>
        </b-row>

        <div v-if="!model.home_address.igual_da_crianca == true">
          <b-row
            v-for="(schema, idx) in schema_home_address"
            :key="`residencial-${idx}`"
          >
            <form-input-simple
              v-for="(formField, idx) in schema"
              v-model="model.home_address[formField.model]"
              :key="idx"
              :formField="formField"
              :errors="errorsHomeAddress"              
            />
          </b-row>
        </div>
      </b-card>
      <b-card
        class="mb-4"
        border-variant="secondary"
        header="Endereço Comercial"
        header-text-variant="secondary"
      >
        <b-row v-if="!same_data_from_financial">
          <b-col md="12">
            <label
              >O Endereço Comercial do Responsável Legal é o mesmo endereço
              do aluno?
            </label>
            <div class="form-check form-check-inline">
              <input
                v-model="business_address_igual_da_crianca"
                class="form-check-input"
                type="radio"
                id="legal_business_address_resp1"
                :value="true"
                @change="businessAddressIgualDaCrianca(true)"
              />
              <label class="form-check-label" for="legal_business_address_resp1"
                >SIM</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                v-model="business_address_igual_da_crianca"
                class="form-check-input"
                type="radio"
                id="legal_business_address_resp2"
                :value="false"
                @change="businessAddressIgualDaCrianca(false)"
              />
              <label class="form-check-label" for="legal_business_address_resp2"
                >Não</label
              >
            </div>
          </b-col>
        </b-row>

        <div v-if="!model.business_address.igual_da_crianca == true">
          <b-row
            v-for="(schema, idx) in schema_business_address"
            :key="`comercial-${idx}`"
          >
            <form-input-simple
              v-for="(formField, idx) in schema"
              v-model="model.business_address[formField.model]"
              :key="idx"
              :errors="errorsBusinessAddress"
              :formField="formField"
              
            />
          </b-row>
        </div>
      </b-card>
    </div>
    <SweetModal ref="loadData" hide-close-button @close="scroll()"
      ><div class="d-flex flex-column align-items-center">
        <img src="/gif/loader.gif" width="250" />
        <span class="lead">Carregando dados...</span>
      </div>
    </SweetModal>
  </div>

  
</template>

<script>
import axios from 'axios'
const initialState = {
  name: '',
  cpf: '',
  rg: '',
  birthday: '',
  nationality: '',
  marital_state: '',
  home_address: {
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    district: '',
    state: '',
  },
  business_address: {
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    district: '',
    state: '',
  },
  contact: {
    home_phone: '',
    business_phone: '',
    cell_phone: '',
    email: '',
    relationship: '',
  },
}
export default {
  props: ['parent_data'],
  data() {
    return {
      ready: true,
      same_data_from_financial: false,
      errors: [],
      errorsHomeAddress: [],
      errorsBusinessAddress: [],
      errorsContact: [],
      home_address_igual_da_crianca: '',
      business_address_igual_da_crianca: '',
      initialModelState: {},
      model: {
        name: '',
        cpf: '',
        rg: '',
        birthday: '',
        nationality: 'Brasileiro',
        marital_state: '',
        home_address: {
          zip_code: '',
          address: '',
          number: '',
          complement: '',
          district: '',
          state: '',
          city: '',
        },
        business_address: {
          zip_code: '',
          address: '',
          number: '',
          complement: '',
          district: '',
          state: '',
          city: '',
        },
        contact: {
          home_phone: '',
          business_phone: '',
          cell_phone: '',
          email: '',
          relationship: '',
        },
      },
      schema_info: [
        [
          {
            label: 'Nome',
            model: 'name',
            size: '6',
            type: 'text',
            required: true,
          },
          {
            label: 'CPF',
            model: 'cpf',
            size: '3',
            type: 'number',
            required: true,
            mask: ['###.###.###-##'],
          },
          { label: 'RG', model: 'rg', size: '3', type: 'text', required: true },
        ],
        [
          {
            label: 'Data de Nascimento',
            model: 'birthday',
            size: '4',
            type: 'number',
            mask: ['##-##-####'],
            required: true,
            masked: true,
          },
          {
            label: 'Nacionalidade',
            model: 'nationality',
            size: '4',
            type: 'text',
            required: true,
          },
          {
            label: 'Estado Civil',
            model: 'marital_state',
            size: '4',
            type: 'select',
            selectOptions: [
              { description: 'Casado', value: 'casado' },
              { description: 'Solteiro', value: 'solteiro' },
              { description: 'Divorciado', value: 'divorciado' },
            ],
            required: true,
          },
        ],
      ],
      schema_contact: [
        [
          {
            label: 'Telefone Res.',
            model: 'home_phone',
            size: '4',
            type: 'number',
            mask: ['(##) ####-####'],
          },
          {
            label: 'Telefone Com.',
            model: 'business_phone',
            size: '4',
            type: 'number',
            mask: ['(##) ####-####'],
            masked: true,
          },
          {
            label: 'Celular',
            model: 'cell_phone',
            size: '4',
            type: 'number',
            mask: ['(##) # ####-####'],

            required: true,
          },
        ],
        [
          {
            label: 'Email',
            model: 'email',
            size: '9',
            type: 'text',
            required: true,
          },
          {
            label: 'Parentesco',
            model: 'relationship',
            size: '3',
            type: 'text',
            required: true,
          },
        ],
      ],
      schema_home_address: [
        [
          {
            label: 'CEP',
            model: 'zip_code',
            size: '4',
            type: 'number',
            mask: ['##.###-###'],

            required: true,
          },
          {
            label: 'Endereço',
            model: 'address',
            size: '4',
            type: 'text',
            required: true,
          },
          {
            label: 'Numero',
            model: 'number',
            size: '4',
            type: 'number',
            mask: ['###########################################'],
            required: true,
          },
        ],
        [
          {
            label: 'Complemento',
            model: 'complement',
            size: '3',
            type: 'text',
          },
          {
            label: 'Bairro',
            model: 'district',
            size: '3',
            type: 'text',
            required: true,
          },
          {
            label: 'Cidade',
            model: 'city',
            size: '3',
            type: 'text',
            required: true,
          },
          {
            label: 'Estado',
            model: 'state',
            size: '3',
            type: 'select',
            selectOptions: this.$store.state.reEnrolls.estados,
            required: true,
          },
        ],
      ],
      schema_business_address: [
        [
          {
            label: 'CEP',
            model: 'zip_code',
            size: '4',
            type: 'number',
            mask: ['##.###-###'],
            required: true,
          },
          {
            label: 'Endereço',
            model: 'address',
            size: '4',
            type: 'text',
            required: true,
          },
          {
            label: 'Numero',
            model: 'number',
            size: '4',
            type: 'number',
            mask: ['###########################################'],
            required: true,
          },
        ],
        [
          {
            label: 'Complemento',
            model: 'complement',
            size: '3',
            type: 'text',
          },
          {
            label: 'Bairro',
            model: 'district',
            size: '3',
            type: 'text',
            required: true,
          },
          {
            label: 'Cidade',
            model: 'city',
            size: '3',
            type: 'text',
            required: true,
          },
          {
            label: 'Estado',
            model: 'state',
            size: '3',
            type: 'select',
            selectOptions: this.$store.state.reEnrolls.estados,
            required: true,
          },
        ],
      ],
    }
  },
  components:{
    FormInputSimple:()=>import('@/components/FormInputSimple')
  },
  watch: {
    'model.home_address.zip_code': {
      handler(val) {
        this.get_zip_code(val, 'home')
      },
    },
    'model.business_address.zip_code': {
      handler(val) {
        this.get_zip_code(val, 'business')
      },
    },   
    same_data_from_financial: {
      handler(v) {
        if (v) {
          this.$refs.loadData.open()
          this.ready = false
          setTimeout(() => {
            this.model = {...this.$parent.$parent.$parent.model.financial_data}
            this.ready = true
            this.$refs.loadData.close()
          }, 300)
        } else {
          this.model = initialState
        }
      },
    },
  },
  methods: {
    scroll() {
      const el = document.getElementsByClassName('form-wizard-steps')[0]
      console.log(el)
      setTimeout(() => {
        window.scrollTo(0, el.offsetTop)
      }, 100)
    },
    homeAddressIgualDaCrianca(value) {
      console.log('home igual', value)
      const address_params = Object.keys(this.model.home_address)
      console.log(address_params) 
      if (value) {
        const dados_crianca = this.parent_data.client
        _.forEach(address_params, (param) => {
          this.model.home_address[param] = dados_crianca[param]
        })
      } else {
        _.forEach(address_params, (param) => {
          this.model.home_address[param] = ''
        })
      }
    },
    businessAddressIgualDaCrianca(value) {
      console.log('business igual', value)
      
      const address_params = Object.keys(this.model.business_address)

      console.log(address_params)
      if (value) {
        const dados_crianca = this.parent_data.client
        _.forEach(address_params, (param) => {
          this.model.business_address[param] = dados_crianca[param]
        })
      } else {
        _.forEach(address_params, (param) => {
          this.model.business_address[param] = ''
        })
      }
    },
    checkRequired() {
      this.errors = []
      this.errorsHomeAddress = []
      this.errorsBusinessAddress = []
      this.errorsContact = []
      const required_model = _.filter(
        _.flatten(this.schema_info),
        (required) => {
          return required.required == true
        }
      ).map((r) => r.model)

      const required_home_address = _.filter(
        _.flatten(this.schema_home_address),
        (required) => {
          return required.required == true
        }
      ).map((r) => r.model)
      const required_business_address = _.filter(
        _.flatten(this.schema_business_address),
        (required) => {
          return required.required == true
        }
      ).map((r) => r.model)
      const required_contact = _.filter(
        _.flatten(this.schema_contact),
        (required) => {
          return required.required == true
        }
      ).map((r) => r.model)

      _.forEach(required_model, (required) => {
        if (this.model[required] == '') {
          this.errors.push(required)
        }
      })
      _.forEach(required_contact, (required) => {
        if (this.model.contact[required] == '') {
          this.errorsContact.push(required)
        }
      })

      _.forEach(required_home_address, (required) => {
        if (this.model.home_address[required] == '') {
          this.errorsHomeAddress.push(required)
        }
      })

      _.forEach(required_business_address, (required) => {
        if (this.model.business_address[required] == '') {
          this.errorsBusinessAddress.push(required)
        }
      })

      if (
        this.errors.length > 0 ||
        this.errorsHomeAddress.length > 0 ||
        this.errorsBusinessAddress.length > 0 ||
        this.errorsContact.length > 0
      ) {
        return false
      } else {
        if (this.validaCpf(this.model.cpf)) {
          return true
        } else {
          this.makeToast('Número de CPF inválido')

          return false
        }
      }
    },
    get_zip_code(value, type) {
      if (String(value).length > 8) {
        this.model.home_address.zip_code = String(
          this.model.home_address.zip_code
        ).slice(0, -1)
      } else {
        if (String(value).length == 8) {
          const address = `${type}_address`
          axios
            .get(
              `https://viacep.com.br/ws/${this.model[address].zip_code}/json/`
            )
            .then((res) => {
              this.model[address].address = res.data.logradouro
              this.model[address].district = res.data.bairro
              this.model[address].state = res.data.uf
              this.model[address].city = res.data.localidade
            })
        }
      }
    },
    getModel(){
      return this.model
    }    
  },
}
</script>

<style>
</style>